import React from "react"
import {
  BlogCard,
  Container,
  BlogImage,
  BlogDescription,
  BlogTitle,
  BlogReadTime,
} from "../../../ToolsStyles/BlogSectionStyled"

import BlogImage1 from "../../Assets/split.jpeg"
import BlogImage2 from "../../Assets/HowToCropImage.avif"
import BlogImage3 from "../../Assets/extract.jpeg"
import BlogImage4 from "../../Assets//BlogImage4.png"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { navigate } from "gatsby"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1174 },
    items: 4,
  },
  laptop: {
    breakpoint: { max: 1174, min: 900 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 900, min: 400 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
  },
}
const BlogSection = () => {
  return (
    <Container>
      <Carousel responsive={responsive}>
        <BlogCard onClick={() => navigate("/blog/guides/how-to-rotate-a-pdf/")}>
          <BlogImage src={BlogImage4} alt="blog-image" />
          <BlogTitle>How to Rotate a PDF in 2023</BlogTitle>
          <BlogDescription>
            Rotating a PDF is one of the most common and basic tasks that are
            done on PDFs.
          </BlogDescription>
          <BlogReadTime>5 min read</BlogReadTime>
        </BlogCard>

        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-split-pdf-pages/")}
        >
          <BlogImage src={BlogImage1} alt="blog-image" />
          <BlogTitle>
            How to Split PDF Pages for Free on Any Device (2023)
          </BlogTitle>
          <BlogDescription>
            Learn how to split PDF pages with our step-by-step guide. Free,
            online, or with a PDF editor, we cover all these, for Windows & Mac.
          </BlogDescription>
          <BlogReadTime>5 min read</BlogReadTime>
        </BlogCard>

        <BlogCard
          onClick={() =>
            navigate("/blog/guides/how-to-extract-pages-from-pdf/")
          }
        >
          <BlogImage src={BlogImage3} alt="blog-image" />
          <BlogTitle>How to Extract Pages from PDF (2023)</BlogTitle>
          <BlogDescription>
            Have you ever needed to extract specific pages from a PDF document?
            This guide has everything you need to extract PDF pages like a pro.
          </BlogDescription>
          <BlogReadTime>7 min read</BlogReadTime>
        </BlogCard>
        <BlogCard onClick={() => navigate("/blog/guides/how-to-crop-a-pdf/")}>
          <BlogImage src={BlogImage2} alt="blog-image" />
          <BlogTitle>How to Crop a PDF: 2023 Guide</BlogTitle>
          <BlogDescription>
            Learn how to crop PDF pages with PDF Pro, Windows, Mac, Microsoft
            Word, LibreOffice, Google Drive/Google Docs, Preview/Mac, PDF
            Expert. Free and paid tools.
          </BlogDescription>
          <BlogReadTime>3 min read</BlogReadTime>
        </BlogCard>
      </Carousel>
    </Container>
  )
}

export default BlogSection
