import React from "react"
import {
  ChildContainer,
  Description,
  ParentContainer,
  Title,
  Image,
  TitleSub,
  SubText,
  Ul,
  LearnMore,
  SvgHolder,
  TitleHolder,
} from "../../../ToolsStyles/UsefulToolsStyled"
import Rearrange from "../icons/image.svg"
import Merge from "../icons/merge.svg"

const UsefulTools = () => {
  return (
    <ParentContainer>
      <TitleHolder>
        <Title>Continue to organize this PDF</Title>
        <Description>
          Here are a couple other tools that you may want to use on your current
          PDF document.
        </Description>
        <LearnMore to="/pdf-online-tools/">
          View all Online tools &rarr;
        </LearnMore>
      </TitleHolder>

      <ChildContainer>
        <SvgHolder>
          <Merge />
        </SvgHolder>

        <TitleSub>Merge PDF</TitleSub>

        <SubText>Combine two or more PDFs into a single PDF.</SubText>
        <LearnMore to="/merge-pdf/">Continue &rarr;</LearnMore>
      </ChildContainer>

      <ChildContainer>
        <SvgHolder>
          <Rearrange />
        </SvgHolder>

        <TitleSub>Rearrange PDF Pages</TitleSub>
        <SubText>Rearrange PDF Pages in a PDF.</SubText>
        <LearnMore to="/rearrange-pdf-pages/">Continue &rarr;</LearnMore>
      </ChildContainer>
    </ParentContainer>
  )
}

export default UsefulTools
