import React, { useEffect } from "react"
import styled from "styled-components"

const BoxStyle = styled.div`
  width: 80px;
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 4px 16px -8px rgba(99, 99, 99, 0.45);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14px 8px;
  cursor: pointer;
`

const Name = styled.div`
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #333333;
  margin-top: 10px;
  text-decoration: capitalize;
  white-space: nowrap;
`

const SvgHolder = styled.div`
  max-width: 16px;
  max-height: 16px;
`

function RotateBox({ svg, name, rotation }) {
  useEffect(() => {
    let pdfdisplays = document.querySelectorAll(".pdfdisplay.checked")
    pdfdisplays.forEach(e => {
      console.log(e)
    })
  }, [])

  function circular(input) {
    var result = input % 360
    return result < 0 ? result + 360 : result
  }

  function CheckIfRotated() {
    const parent = document.getElementById("canvas-storage")
    let oneOfElementsIsRotated = false
    Array.prototype.forEach.call(parent.children, childElement => {
      const pdfDisplay = childElement.firstChild.children[1]
      let transform = pdfDisplay.style.transform
      let match = transform.match(/rotate\((\d+)deg\)/)
      let rotation = match ? parseInt(match[1]) : 0
      console.log("Rotate: ", rotation)
      if (rotation && rotation != 0) {
        oneOfElementsIsRotated = true
      }
    })

    if (oneOfElementsIsRotated) {
      const button = document.getElementById("reset-position")
      button.style.display = "flex"
    } else {
      const button = document.getElementById("reset-position")

      button.style.display = "none"
    }
  }

  const Rotate = () => {
    if (rotation != 0) {
      let pdfdisplays = document.querySelectorAll(".pdfdisplay.checked")
      let pdfdisplaysMobile = document.querySelectorAll(".pdfdisplay")

      let message = document.getElementById("message")
      let canvasHolder = document.getElementById("canvas-storage")

      if (canvasHolder.children.length == 1) {
        message.innerText = "Now you can export rotated PDF"
      }
      console.log(pdfdisplays)
      pdfdisplays.forEach(e => {
        const pdfDisplay = e.firstChild.children[1]
        let rect = pdfDisplay.getBoundingClientRect()

        let transform = pdfDisplay.style.transform
        let match = transform.match(/rotate\((\d+)deg\)/)
        let currentRotation = match ? parseInt(match[1]) : 0
        console.log(currentRotation)
        pdfDisplay.style.transform = `rotate(${circular(
          currentRotation + rotation
        )}deg)`

        if (rotation != 180) {
          pdfDisplay.parentNode.style.height = rect.width + "px"
          pdfDisplay.parentNode.style.width = rect.height + "px"
        }

        e.style.height = "100%"
        console.log(rect.width, rect.height)
      })

      // if (pdfdisplays.length == 0 && window.innerWidth < 744) {
      //   pdfdisplaysMobile.forEach(e => {
      //     const pdfDisplay = e.firstChild.children[1]
      //     let rect = pdfDisplay.getBoundingClientRect()

      //     let transform = pdfDisplay.style.transform
      //     let match = transform.match(/rotate\((\d+)deg\)/)
      //     let currentRotation = match ? parseInt(match[1]) : 0
      //     console.log(currentRotation)
      //     pdfDisplay.style.transform = `rotate(${circular(
      //       currentRotation + rotation
      //     )}deg)`

      //     if (rotation != 180) {
      //       pdfDisplay.parentNode.style.height = rect.width + "px"
      //       pdfDisplay.parentNode.style.width = rect.height + "px"
      //     }

      //     e.style.height = "100%"
      //     console.log(rect.width, rect.height)
      //   })
      // }
      CheckIfRotated()
    }
  }

  return (
    <BoxStyle onClick={Rotate}>
      <SvgHolder>{svg}</SvgHolder>
      <Name>{name}</Name>
    </BoxStyle>
  )
}

export default RotateBox
