import React, { useEffect } from "react"
import styled from "styled-components"
import Left from "../../../icons/tools/rotate/Rotate_left_button.svg"
import Right from "../../../icons/tools/rotate/Rotate_right_button.svg"
import RotateLeft from "../../../icons/tools/rotate/rotateLeft.svg"
import RotateRight from "../../../icons/tools/rotate/rotateRight.svg"
import Check from "../../../icons/tools/rotateCheck.svg"
import RotateBox from "./RotateBox"
const PdfdisplayStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 440px) {
    justify-content: center;
  }
  min-height: 283px;
  min-width: max-content;
  width: 100%;
  max-width: max-content;
  /* transition: order 1s; */
  order: 10;
  img {
    max-width: max-content;
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0);
    transition: border 0.5s, outline 0.5s;
    outline: rgba(216, 219, 253, 0) solid 10px;
  }
  :hover {
    img {
      outline: rgba(216, 219, 253, 0.5) solid 10px;

      border: 1px solid #425466;
    }
  }
  .checked {
    ${props =>
      !props.oneDisplay &&
      `
    img {
      border: 2px solid #6361ff;
    }
    .check {
      opacity: 1;
      circle {
        fill: #6361ff;
      }
      path {
        stroke: #6361ff;
      }
    }
    `}
  }
`
const Name = styled.div`
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0b263f;
  margin-top: 16px;
  max-width: 200px;
  min-height: 48px;
  max-height: 48px;
  display: block;
  white-space: normal;

  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const ImageHolder = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 13px 27px -5px rgba(50, 50, 93, 0.25),
    0px 8px 16px -8px rgba(0, 0, 0, 0.3);

  ${props =>
    props.oneDisplay &&
    `box-shadow: 0px 50px 100px -20px rgba(50, 50, 93, 0.25), 0px 30px 60px -30px rgba(0, 0, 0, 0.3);`}

  .check {
    ${props => props.oneDisplay && `display: none;`}
    position: absolute;
    opacity: 0;
    transition: opacity 0.2s;
    z-index: 10;
    right: 0;
    top: 0;
    transform: translate(12px, -12px);
    circle {
      transition: fill 0.2s;
      fill: #cdcdcd;
    }
    path {
      transition: stroke 0.2s;
      stroke: #cdcdcd;
    }
  }

  ${props =>
    props.oneDisplay &&
    `
    .leftB {
      display: none;
    }

    .rightB {
      display: none;
    }
  @media (max-width: 540px) {
    margin-bottom: 32px;

    max-width: 283px;
    max-height: 283px;
    width: 100%;
    height: 100%;
    
    img {
      max-width: 283px;
      max-height: 283px;
    }
  }

  @media (min-width: 540px) {

    max-width: 492px;
      max-height: 492px;
    img {
      max-width: 492px;
      max-height: 492px;
    }
  }
  `}

  .right {
    ${props => props.oneDisplay && `display: none;`}
    transition: opacity 0.2s;
    opacity: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
    @media (pointer: coarse) {
      display: none;
    }
  }

  .left {
    ${props => props.oneDisplay && `display: none;`}
    transition: opacity 0.2s;
    opacity: 0;
    position: absolute;
    right: 40px;
    bottom: 0;
    cursor: pointer;
    @media (pointer: coarse) {
      display: none;
    }
  }

  :hover {
    svg {
      opacity: 1;
    }

    .leftB {
      opacity: 1;
    }

    .rightB {
      opacity: 1;
    }
  }

  .leftB {
    left: 14px;
    bottom: 30%;
  }

  .rightB {
    right: 14px;
    bottom: 30%;
  }
  @media (max-width: 440px) {
    .leftB {
      display: none;
    }

    .rightB {
      display: none;
    }
  }

  @media (max-width: 774px) {
    .left {
      display: none;
    }

    .right {
      display: none;
    }
  }

  .leftB {
    display: none;
  }

  .rightB {
    display: none;
  }
`

const RotateHolder = styled.div`
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;

  @media (min-width: 774px) {
    display: none;
  }
`

function PdfdisplayRotate({ element, name, file, order, oneDisplay }) {
  return (
    <PdfdisplayStyle
      oneDisplay={oneDisplay}
      className={`pdfdisplay ${oneDisplay && "checked"}`}
      id={`pdfdisplay` + order}
    >
      <ImageHolder
        oneDisplay={oneDisplay}
        className={`${oneDisplay && "checked"}`}
      >
        <Check className="check" />
        <img src={element} alt="Pdf display" draggable="false" />
        <Right className="right" id={`right` + order} file={file} />
        <Left className="left" id={`left` + order} file={file} />
        <RotateHolder className="leftB">
          <RotateBox name={"Rotate left"} svg={<RotateLeft />} rotation={0} />
        </RotateHolder>
        <RotateHolder className="rightB">
          <RotateBox name={"Rotate right"} svg={<RotateRight />} rotation={0} />
        </RotateHolder>
      </ImageHolder>
      {!oneDisplay && <Name>{name} </Name>}
    </PdfdisplayStyle>
  )
}

export default PdfdisplayRotate
