import React from "react"
import {
  Container,
  Title,
  Description,
  DescriptionBox,
  SvgHolder,
} from "../../../ToolsStyles/ShortDescriptionStyled"

import Desc1 from "../../../../../icons/tools/rotate/Rotate.svg"
import Desc2 from "../../../../../icons/tools/rotate/WhatCanIRotateIcon.svg"
import Desc3 from "../../../../../icons/tools/lp/merge/DescIcon3.svg"
import Desc4 from "../../../../../icons/tools/lp/merge/DescIcon4.svg"
import Desc5 from "../../../../../icons/tools/lp/merge/DescIcon5.svg"
import Desc6 from "../../../../../icons/tools/lp/merge/DescIcon6.svg"

const ShortDescriptions = () => {
  return (
    <Container>
      <DescriptionBox>
        <SvgHolder>
          <Desc1 />
        </SvgHolder>
        <Title>Rotate Specific Pages or Entire PDF Documents</Title>
        <Description>
          Once you've uploaded your document, you'll be able to select which
          page(s) to rotate. Rotate a single page or entire documents in just a
          few clicks.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc2 />
        </SvgHolder>
        <Title>What Can I Rotate?</Title>
        <Description>
          Rotating PDFs can help you organize downloaded documents, scanned
          documents, ebooks, fillable forms, contracts, images, and more.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc3 />
        </SvgHolder>
        <Title>Safe & Secure — Your Privacy Matters to Us</Title>
        <Description>Uploaded files are deleted after 24 hours.</Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc4 />
        </SvgHolder>
        <Title>100% Free to Use</Title>
        <Description>
          Our PDF rotator is totally free! No limits on the number of pages you
          can rotate. Rotate PDFs to your heart's content.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc5 />
        </SvgHolder>
        <Title>No installation or sign-up required</Title>
        <Description>
          No downloading software, no signing up necessary. Just visit this
          webpage from your browser, and get to rotating!
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc6 />
        </SvgHolder>
        <Title>Works on Any Device</Title>
        <Description>
          Our online PDF rotator works from within your browser. This means that
          you can rotate PDFs on any device and system, not just a computer.
        </Description>
      </DescriptionBox>
    </Container>
  )
}

export default ShortDescriptions
