import client from "../toolApi"
import { DataLayer } from "../../helpers/GA"
export const RotateFiles = (data, setDownload) =>
  client
    .post("/uploadNew", data.formData)
    .then(res => {
      DataLayer("Online tool", "Upload", "Rotate")
      window.fileName = res.data.message
      client
        .get(
          `/rotateTool?degreeLeft=${data.degreeLeft}&degreeFlip=${data.degreeFlip}&degreeRight=${data.degreeRight}&fileName=${res.data.message}&password=${data.password}`
        )
        .then(res => {
          DataLayer("Online tool", "Rotate", "Rotate")
          client
            .get(`/download?fileName=${window.fileName}`, {
              responseType: "blob",
            })
            .then(res => {
              console.log("Downloaded: ", res)
              const link = document.createElement("a")
              setDownload(link)
              link.href = URL.createObjectURL(new Blob([res.data]))
              link.download = "pdfpro_rotate.pdf"
              document.body.appendChild(link)
              link.click()
              DataLayer("Online tool", "Download", "Rotate")
            })
            .catch(() => {
              DataLayer("Online tool", "Error download", "Rotate")
            })
        })
        .catch(() => {
          DataLayer("Online tool", "Error rotate", "Rotate")
        })
    })
    .catch(() => {
      DataLayer("Online tool", "Error upload", "Rotate")
    })
