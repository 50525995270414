import React, { useState, useEffect, useRef, useContext } from "react"
import { GlobalAuthContext } from "../../../Contexts/GlobalAuthContext"
import styled from "styled-components"
// import { Document, Page } from "react-pdf"
// import pdfjs from "pdfjs-dist"
import displayPdfSyncRotate from "../helpers/displayPdf-Rotate"
import { FileUploader } from "react-drag-drop-files"
import TryPdf from "../../shared/TryPdf"
import Add from "../../../icons/tools/Add.svg"
import ArrowRight from "../../../icons/tools/arrow-right.svg"
import RotateIcon from "../../../icons/tools/Rotate_icon.svg"
import MergedIcon from "../../../icons/tools/mergedIcon.svg"
import ErrorMessage from "../ErrorMessage"
import UploadIcon from "../../../icons/tools/upload.svg"
import RotateLeft from "../../../icons/tools/rotate/rotateLeft.svg"
import RotateRight from "../../../icons/tools/rotate/rotateRight.svg"
import Rotate180 from "../../../icons/tools/rotate/rotate180.svg"
import ArrowTry from "../../../icons/tools/tryPdfArrow.svg"
import { RotateFiles } from "./Actions"
import { Link } from "gatsby"
import RotateBox from "./RotateBox"
import Warning from "../Warning"
import PrivacyQuestion from "../../../icons/tools/PrivacyQuestion.svg"
import GDPRwarning from "../GlobalToolComponents/GDPRwarning"
import Title from "../GlobalToolComponents/ToolH2tag"
import RestartIcon from "../../../icons/tools/Restart.svg"
import { formatBytes } from "../helpers/helpers"
import {
  DownloadScreen,
  CanvasStyle,
  LoadingScreen,
  Pdfpreview,
} from "../Styles"
import Disclaimer from "../GlobalToolComponents/Disclaimer"
import UsefulTools from "./Landing/UsefulTools/UsefulTools"

const LoadingCircle = styled.div`
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
const FileUploaderStyle = styled.div`
  margin: auto;
  max-width: 532px;
  width: 100%;
  margin-top: 60px;

  .drop_zone {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed red;
    width: 100%;
    min-height: 374px;
    background: #f5f6fc;
    border: 1px dashed #6361ff;
    border-radius: 2px;
    margin: 8px 0;
  }

  :hover {
    max-width: 548px;
    .drop_zone {
      min-height: 390px;
      background: #ebeeff;
      margin: 0;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 18px;
      line-height: 28px;
      color: #425466;
      text-align: center;
      margin-top: 25px;
      margin-bottom: 8px;
      :after {
        content: "Drag and drop your PDF file here";
      }
      @media (max-width: 744px) {
        :after {
          content: "Tap to upload PDF file here";
        }
      }
    }
    .info {
      font-size: 13px;
      line-height: 22px;
      text-align: center;
      color: #6361ff;
      text-transform: capitalize;

      :after {
        content: "PDF files smaller than 20MB, max 100 pages";
      }
      @media (max-width: 744px) {
        :after {
          content: "PDF files smaller than 20MB";
        }
      }
    }

    button {
      all: unset;
      margin-top: 40px;
      font-size: 17px;
      line-height: 20px;
      color: #ffffff;
      padding: 12px 24px;
      gap: 12px;
      background: #6361ff;
      border-radius: 8px;
      cursor: pointer;
      @media (max-width: 744px) {
        display: none;
      }
    }
  }
`
const Offline = styled.div`
  background: #e3e8ee;
  border-radius: 2px;
  width: 73px;
  height: 26px;
  font-size: 13px;
  line-height: 22px;
  text-transform: uppercase;
  color: #6a7485;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  :hover {
    .warning {
      opacity: 1;
    }
  }
`

const ToPdf = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #333333;
  a {
    color: #6361ff;
    text-decoration: none;
  }

  .desktop {
    display: inline-block;
    @media (max-width: 600px) {
      display: none;
    }
  }
`
const CtaPopup = styled.div`
  color: #6361ff;
  text-decoration: none;
  display: inline;
  cursor: pointer;
  white-space: nowrap;
`
const SingleFileUploadStyle = styled.div`
  min-width: 205px;
  min-height: 283px;
  width: max-content;
  margin: 0;
  margin-bottom: 90px;
  display: inline-block;

  .drop_zone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 283px;
    background: #f5f6fc;
    border: 1px dashed #6361ff;
    border-radius: 2px;
    cursor: pointer;

    :hover {
      background: #ebeeff;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .title {
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #6361ff;
      font-weight: 800;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .info {
      max-width: 97px;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      color: #6361ff;
      white-space: pre-wrap;
    }
  }
`

const Pdfholder = styled.div`
  min-height: 283px;
  display: flex;
  align-items: center;
  gap: 48px;
  padding-top: 32px;
`

const ExportButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 12px;
  width: 170px;
  white-space: nowrap;
  transition: background-color 1s;
  height: 48px;
  background: #6361ff;
  border-radius: 8px;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;

  svg {
    min-width: 24px;
    min-width: 24px;
  }

  @media (max-width: 440px) {
    width: 90%;
  }
`
const ResetPosition = styled(ExportButton)`
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #6361ff;
  background: #f5f9fc;
  border: 2px solid #6361ff;
  border-radius: 8px;
  display: none;
`

const SecondScreen = styled.div`
  /* ${props => props.hide && "display: none;"} */
  /* transform: translateY(124px); */
`

const ButtonHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;

  /* max-height: 0; */
  overflow: visible;
  @media (max-width: 440px) {
    padding-top: 32px;
    flex-direction: column;
    margin: auto;
    align-items: center;
  }
`

const RotateButtonHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 40px;
  z-index: 10;
  position: relative;
  input {
    width: 24px;
    height: 24px;
    border: 1px solid #d7d6d6;
    border-radius: 0;
    cursor: pointer;
  }
  label {
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #0b263f;
  }

  input[type="checkbox"]:not(:checked) {
    -webkit-appearance: none;
    appearance: none;
  }

  input[type="checkbox"]:checked {
    border-radius: 0;
    background: red;
  }

  @media (max-width: 744px) {
    label,
    input {
      display: none;
    }
  }
`

const Message = styled.div`
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #425466;
  margin-bottom: 8px;
  @media (max-width: 440px) {
    margin-bottom: 0;
    display: none;
  }
`
const Loading = styled.div`
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  color: #333333;
  margin-bottom: 24px;
`

const BarBack = styled.div`
  width: 320px;
  height: 8px;
  background: #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
`

const BarButton = styled.div`
  ${props => `width: ${320 * props.percent}px;`}
  background: #6361FF;
  height: 8px;

  border-radius: 4px;
`
const Percent = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: #8d9092;
  margin-top: 8px;
`

const DownloadTitle = styled.div`
  font-size: 38px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #333333;
  margin: 24px 0;
  @media (max-width: 744px) {
    font-size: 32px;
    line-height: 32px;
  }
`

const DownloadMessage = styled.div`
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #425466;
  @media (max-width: 744px) {
    font-size: 16px;
    line-height: 26px;
  }
`
const DownloadButton = styled.div`
  min-width: 156px;
  height: 48px;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background: #6361ff;
  border-radius: 8px;
  margin-top: 40px;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  @media (max-width: 440px) {
    width: 100%;
  }
`

const ErrorMessageHolder = styled.div`
  background: red;
  height: 100%;
  width: 0px;
  position: absolute;
  left: 50%;
  bottom: 0;
  padding-bottom: 258px;
  padding-top: 258px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 744px) {
    justify-content: flex-start;
  }
`
const Restart = styled(ToPdf)`
  margin-top: auto;
  display: flex;
  margin-bottom: 32px;
  margin-top: 32px;
`

const RestartSecond = styled(Restart)`
  justify-content: center;
  margin-top: 32px;
  position: absolute;
  bottom: 0;
  width: 90vw;
  max-width: 1080px;
`
const CtaRestat = styled(CtaPopup)`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin: 0 8px;
  }
`
const Info = styled.div`
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #425466;
  max-height: 0;
  overflow: visible;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (max-width: 440px) {
    /* display: none; */
  }
`

function RotateTool() {
  const [file, setFile] = useState(null)
  const [errorFiles, setErrorFiles] = useState([])
  const [repository, setRepository] = useState([])
  const [fileRendered, setFileRendered] = useState(0)
  const [pageNum, setPageNum] = useState(null)
  const [filesSize, setFilesSize] = useState(0)
  const [download, setDownload] = useState(null)
  const [errors, setErrors] = useState([])
  const [showAdd, setShowAdd] = useState(false)
  const [rotated, setRotated] = useState(false)

  const [loading, setLoading] = useState(false)

  const pdfHolder = useRef(null)
  const pdfPreview = useRef(null)

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes"

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  useEffect(() => {
    if (pdfHolder?.current) {
      function detectScrollability() {
        if (pdfPreview.current?.scrollWidth) {
          if (pdfPreview.current.scrollWidth > pdfPreview.current.clientWidth) {
            pdfPreview.current.style.justifyContent = "normal"
            setShowAdd(true)
          } else {
            pdfPreview.current.style.justifyContent = "center"
            setShowAdd(false)
          }
        }
      }

      window.addEventListener("resize", () => {
        detectScrollability()
      })
      const observer = new MutationObserver(detectScrollability)

      detectScrollability()
      observer.observe(pdfPreview.current, {
        attributes: true,
        childList: true,
        subtree: true,
      })
    }
  }, [pdfHolder.current, pdfPreview.current?.scrollWidth])

  const { setScroll } = useContext(GlobalAuthContext)

  const [myPopUp, setMyPopUp] = useState("hide")
  function closePopup() {
    setMyPopUp("hide")
    setScroll("hide")
  }
  //open popup function
  function openPopup(e) {
    setMyPopUp("show")
    setScroll("show")
  }

  function circular(input) {
    var result = input % 360
    return result < 0 ? result + 360 : result
  }

  useEffect(() => {
    console.log("pages: ", pageNum)
  }, [pageNum])

  function CheckIfRotated() {
    const parent = document.getElementById("canvas-storage")
    let oneOfElementsIsRotated = false
    Array.prototype.forEach.call(parent.children, childElement => {
      const pdfDisplay = childElement.firstChild.children[1]
      let transform = pdfDisplay.style.transform
      let match = transform.match(/rotate\((\d+)deg\)/)
      let rotation = match ? parseInt(match[1]) : 0
      console.log("Rotate: ", rotation)
      if (rotation && rotation != 0) {
        oneOfElementsIsRotated = true
      }
    })

    if (oneOfElementsIsRotated) {
      const button = document.getElementById("reset-position")
      button.style.display = "flex"
    } else {
      const button = document.getElementById("reset-position")

      button.style.display = "none"
    }
  }

  function SetOriginalRotation() {
    const parent = document.getElementById("canvas-storage")
    Array.prototype.forEach.call(parent.children, childElement => {
      const pdfDisplay = childElement.firstChild.children[1]
      let transform = pdfDisplay.style.transform
      let match = transform.match(/rotate\((\d+)deg\)/)
      let rotation = match ? parseInt(match[1]) : 0
      let rect = pdfDisplay.getBoundingClientRect()

      pdfDisplay.style.transform = `rotate(${circular(0)}deg)`

      if (rotation == 90 || rotation == 270) {
        pdfDisplay.parentNode.style.height = rect.width + "px"
        pdfDisplay.parentNode.style.width = rect.height + "px"
      }
    })
    const button = document.getElementById("reset-position")

    button.style.display = "none"
  }

  useEffect(() => {
    if (file) {
      const parent = document.getElementById("canvas-storage")

      const observer = new MutationObserver((mutationsList, observer) => {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            const addedNodes = mutation.addedNodes
            let i = 1
            for (let node of addedNodes) {
              if (node.nodeType === Node.ELEMENT_NODE) {
                function getAngle(x, y) {
                  const rect = node.getBoundingClientRect()
                  const cx = rect.left + rect.width / 2
                  const cy = rect.top + rect.height / 2
                  return (Math.atan2(y - cy, x - cx) * 180) / Math.PI
                }

                // do something when the element is fully rendered
                const rotateLeft = node.firstChild.children[3]
                const rotateRight = node.firstChild.children[2]
                const rotateLeftButton = node.firstChild.children[4]
                const rotateRightButton = node.firstChild.children[5]

                const pdfDisplay = node.firstChild.children[1]
                if (window.pages != 1) {
                  console.log("Page num: ", window.pages)
                  pdfDisplay.addEventListener("click", e => {
                    node.firstChild.classList.contains("checked")
                      ? node.firstChild.classList.remove("checked")
                      : node.firstChild.classList.add("checked")
                    node.classList.contains("checked")
                      ? node.classList.remove("checked")
                      : node.classList.add("checked")
                  })
                }

                rotateLeft.addEventListener("click", e => {
                  let rect = pdfDisplay.getBoundingClientRect()

                  let transform = pdfDisplay.style.transform
                  let match = transform.match(/rotate\((\d+)deg\)/)
                  let rotation = match ? parseInt(match[1]) : 0
                  console.log(rotation)
                  pdfDisplay.style.transform = `rotate(${circular(
                    rotation - 90
                  )}deg)`

                  pdfDisplay.parentNode.style.height = rect.width + "px"
                  pdfDisplay.parentNode.style.width = rect.height + "px"

                  node.style.height = "100%"
                  console.log(rect.width, rect.height)
                  CheckIfRotated()
                })

                rotateLeftButton.addEventListener("click", e => {
                  let rect = pdfDisplay.getBoundingClientRect()

                  let transform = pdfDisplay.style.transform
                  let match = transform.match(/rotate\((\d+)deg\)/)
                  let rotation = match ? parseInt(match[1]) : 0
                  console.log(rotation)
                  pdfDisplay.style.transform = `rotate(${circular(
                    rotation - 90
                  )}deg)`

                  pdfDisplay.parentNode.style.height = rect.width + "px"
                  pdfDisplay.parentNode.style.width = rect.height + "px"

                  node.style.height = "100%"
                  console.log(rect.width, rect.height)
                  CheckIfRotated()
                })

                rotateRight.addEventListener("click", e => {
                  let rect = pdfDisplay.getBoundingClientRect()

                  let transform = pdfDisplay.style.transform
                  let match = transform.match(/rotate\((\d+)deg\)/)
                  let rotation = match ? parseInt(match[1]) : 0
                  console.log(rotation)
                  pdfDisplay.style.transform = `rotate(${circular(
                    rotation + 90
                  )}deg)`

                  pdfDisplay.parentNode.style.height = rect.width + "px"
                  pdfDisplay.parentNode.style.width = rect.height + "px"

                  node.style.height = "100%"

                  console.log(rect.width, rect.height)
                  CheckIfRotated()
                })

                rotateRightButton.addEventListener("click", e => {
                  let rect = pdfDisplay.getBoundingClientRect()

                  let transform = pdfDisplay.style.transform
                  let match = transform.match(/rotate\((\d+)deg\)/)
                  let rotation = match ? parseInt(match[1]) : 0
                  console.log(rotation)
                  pdfDisplay.style.transform = `rotate(${circular(
                    rotation + 90
                  )}deg)`

                  pdfDisplay.parentNode.style.height = rect.width + "px"
                  pdfDisplay.parentNode.style.width = rect.height + "px"

                  node.style.height = "100%"

                  console.log(rect.width, rect.height)
                  CheckIfRotated()
                })
              }
              i++
            }
          }
        }
      })

      observer.observe(parent, { childList: true })
    }
  }, [file])

  useEffect(() => {
    if (fileRendered == 1) {
      setFileRendered(0)
      setLoading(false)
      console.log(errorFiles)
      if (errorFiles.length == 0) {
        setRepository([file])
        setErrorFiles([])
      } else {
        setErrorFiles([])
        setFile(null)
      }
    }
  }, [fileRendered])

  function removePassProtected(fileName) {
    let str = fileName + " is password protected"
    ErrorPopup(str)
  }

  useEffect(() => {
    console.log("File: ", file)
  }, [file])

  async function pdfInput(file) {
    const fileReader = new FileReader()
    fileReader.onload = async function () {
      const pdfData = new Uint8Array(this.result)
      await displayPdfSyncRotate(
        pdfData,
        file,
        "canvas-storage",
        1,
        setFileRendered,
        removePassProtected,
        setErrorFiles,
        ErrorPopup,
        setPageNum
      )
    }
    fileReader.readAsArrayBuffer(file)
  }

  const fileTypes = ["pdf"]

  const handleChange = file => {
    const formData = new FormData()
    formData.append("excel", file)
    setLoading(true)

    setFile(file)
    pdfInput(file)
  }

  const filesSubmit = () => {
    const formData = new FormData()
    let pdfdisplays = document.querySelectorAll(".pdfdisplay")

    let degreeLeft = ["-l"]
    let degreeFlip = ["-c"]
    let degreeRight = ["-r"]
    console.log(repository)
    let fileName = repository[0].name
    formData.append(repository[0].name, repository[0])
    let rawRight = ""
    let rawLeft = ""
    let rawFlip = ""
    pdfdisplays.forEach((node, i) => {
      const pdfDisplay = node.firstChild.children[1]

      let transform = pdfDisplay.style.transform
      let match = transform.match(/rotate\((\d+)deg\)/)
      let rotation = match ? parseInt(match[1]) : 0

      console.log(i + 1, rotation)

      if (rotation == 90) {
        rawRight += i + 1 + ","
      }
      if (rotation == 270) {
        rawLeft += i + 1 + ","
      }
      if (rotation == 180) {
        rawFlip += i + 1 + ","
      }
    })

    if (rawLeft != "") {
      degreeLeft.push(rawLeft)
    }
    if (rawRight != "") {
      degreeRight.push(rawRight)
    }

    if (rawFlip != "") {
      degreeFlip.push(rawFlip)
    }

    console.log(degreeFlip, degreeLeft, degreeRight)

    if (degreeFlip.length == 1) degreeFlip = ""
    if (degreeLeft.length == 1) degreeLeft = ""
    if (degreeRight.length == 1) degreeRight = ""

    let data = {
      formData: formData,
      fileName: fileName,
      degreeLeft: encodeURIComponent(JSON.stringify(degreeLeft)),
      degreeFlip: encodeURIComponent(JSON.stringify(degreeFlip)),
      degreeRight: encodeURIComponent(JSON.stringify(degreeRight)),
      password: window.realPass,
    }

    if (
      degreeFlip.length > 1 ||
      degreeLeft.length > 1 ||
      degreeRight.length > 1
    ) {
      setDownload(true)
      RotateFiles(data, setDownload)
    } else {
      ErrorPopup("No pages were rotated")
    }
  }
  function restartTool(e) {
    window.location.reload()
  }

  useEffect(() => {
    let size = 0
    repository.forEach(f => {
      size += f.size
    })

    setFilesSize(formatBytes(size, 2))
  }, [repository])

  function ErrorPopup(message) {
    let oldEr = [...errors, message]
    setErrors(er => [...er, message])
    setTimeout(() => {
      if (
        oldEr.length == errors.length &&
        JSON.stringify(oldEr) == JSON.stringify(errors)
      ) {
        setErrors([])
      }
    }, 5000)
  }
  if (download != null) {
    return (
      <DownloadScreen>
        <MergedIcon />
        {download === true && (
          <DownloadTitle>Your PDF pages are now being rotated</DownloadTitle>
        )}

        {download !== true && (
          <DownloadTitle>Your PDF pages have been rotated</DownloadTitle>
        )}
        <DownloadMessage>
          Your PDF file will automatically download once it is ready.
        </DownloadMessage>
        {download === true && (
          <DownloadButton>
            <LoadingCircle />
            Processing...
          </DownloadButton>
        )}
        {download !== true && (
          <DownloadButton onClick={() => download.click()}>
            Download File
          </DownloadButton>
        )}
        <Restart>
          <div className="desktop">Need to rotate another file?</div>
          <CtaRestat onClick={restartTool}>
            <RestartIcon /> Start Over{" "}
          </CtaRestat>
        </Restart>

        <UsefulTools />
      </DownloadScreen>
    )
  }

  return (
    <CanvasStyle>
      <ErrorMessageHolder>
        {errors.map(m => {
          return <ErrorMessage message={m}></ErrorMessage>
        })}
      </ErrorMessageHolder>
      {!file && (
        <div className="firstScreen">
          <RotateIcon />
          <h1>Rotate PDFs Online</h1>
          <Title>Easily Rotate PDFs Online for Free</Title>
          <FileUploaderStyle>
            <FileUploader
              handleChange={handleChange}
              multiple={false}
              types={fileTypes}
              maxSize={20}
              onSizeError={() => {
                ErrorPopup("Your file needs to be under 5MB size")
              }}
              classes="drop_area drop_zone "
              children={
                <div className="column">
                  <UploadIcon />
                  <div className="title"></div>
                  <div className="info"></div>
                  <button>Upload file</button>
                </div>
              }
            />
          </FileUploaderStyle>
          <Row>
            <Offline>Offline</Offline>
            <ToPdf>
              <div className="desktop">Rather work offline?&nbsp;</div>
              <CtaPopup onClick={openPopup}>
                Try PDF Pro for free today! <ArrowTry />{" "}
              </CtaPopup>
            </ToPdf>
          </Row>
          <Row>
            <Warning />
            <GDPRwarning>
              <Disclaimer /> <PrivacyQuestion />
            </GDPRwarning>
          </Row>
          {myPopUp === "show" && <TryPdf close={closePopup} />}
        </div>
      )}
      <LoadingScreen hide={loading}>
        <Loading>Loading... </Loading>
        <BarBack>
          <BarButton percent={fileRendered} />{" "}
        </BarBack>
        <Percent>{(fileRendered * 100).toFixed(0)}%</Percent>
      </LoadingScreen>
      {file && (
        <SecondScreen>
          {/* {pageNum == 1 && (
          )} */}
          <Message id="message">
            Use the Rotate buttons below to rotate your PDF pages
          </Message>

          {/* {pageNum != 1 && (
            <Message id="message">Use the Rotate buttons below to rotate your PDF pages</Message>
          )} */}

          <Pdfpreview ref={pdfPreview} overflowY={pageNum == 1}>
            <Pdfholder id="canvas-storage" ref={pdfHolder}>
              {" "}
            </Pdfholder>
          </Pdfpreview>
          <RotateButtonHolder>
            <RotateBox
              name={"Rotate left"}
              svg={<RotateLeft />}
              rotation={-90}
            />
            <RotateBox
              name={"Rotate right"}
              svg={<RotateRight />}
              rotation={90}
            />
            <RotateBox
              name={"Rotate 180’"}
              svg={<Rotate180 />}
              rotation={180}
            />
            {pageNum != 1 && (
              <input
                type="checkbox"
                id="scales"
                onChange={e => {
                  let pdfdisplays = document.querySelectorAll(".pdfdisplay")

                  if (e.target.checked) {
                    pdfdisplays.forEach(node => {
                      node.firstChild.classList.add("checked")
                      node.classList.add("checked")
                    })
                  } else {
                    pdfdisplays.forEach(node => {
                      node.firstChild.classList.remove("checked")
                      node.classList.remove("checked")
                    })
                  }
                }}
              />
            )}
            {pageNum != 1 && <label for="scales"> Apply To All Pages</label>}
          </RotateButtonHolder>

          <ButtonHolder>
            <ResetPosition id="reset-position" onClick={SetOriginalRotation}>
              Reset Position
            </ResetPosition>

            <ExportButton onClick={filesSubmit}>
              Export PDF <ArrowRight />
            </ExportButton>
          </ButtonHolder>
          <Info>
            {file.name}, {pageNum} Pages, {filesSize}
          </Info>
          <RestartSecond>
            <div className="desktop">Need to rotate another file?</div>
            <CtaRestat onClick={restartTool}>
              <RestartIcon /> Start Over{" "}
            </CtaRestat>
          </RestartSecond>
        </SecondScreen>
      )}
    </CanvasStyle>
  )
}

export default RotateTool
